
import React from 'react';
import './Welcome.css';

const Welcome = () => {
  return (
    <div className="welcome-container">
      <h1>Bienvenido</h1>
      <img src={`${process.env.PUBLIC_URL}/jujutsu.avif`} alt="Bienvenido" className="welcome-image" />
    </div>
  );
}

export default Welcome;
